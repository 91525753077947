// third-party
import { FormattedMessage } from 'react-intl';
import { FaBoxOpen, FaCartPlus, FaCog, FaMoneyBillWave } from 'react-icons/fa';
import { CgNotes } from 'react-icons/cg';
import { TbTruckReturn } from 'react-icons/tb';

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'Products',
  title: <FormattedMessage id="Products" />,
  icon: FaCog,
  type: 'group',
  children: [
    {
      id: 'Products',
      title: <FormattedMessage id="Products" />,
      type: 'collapse',
      icon: FaBoxOpen,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/product'
        }
      ]
    },
    {
      id: 'Request',
      title: <FormattedMessage id="Request" />,
      type: 'collapse',
      icon: CgNotes,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/request'
        },
        {
          id: 'Return Request',
          title: <FormattedMessage id="Return Request" />,
          type: 'item',
          url: '/request/return-request'
        }
      ]
    },
    {
      id: 'Orders',
      title: <FormattedMessage id="Orders" />,
      type: 'collapse',
      icon: FaCartPlus,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/order'
        },
        {
          id: 'Product Return',
          title: <FormattedMessage id="Product Return" />,
          type: 'item',
          url: '/order/return'
        }
      ]
    },
    {
      id: 'Return',
      title: <FormattedMessage id="Return" />,
      type: 'collapse',
      icon: TbTruckReturn,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/return'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/return/manage'
        }
      ]
    },
    {
      id: 'Earnings',
      title: <FormattedMessage id="Earnings" />,
      type: 'collapse',
      icon: FaMoneyBillWave,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/earnings'
        },
        // {
        //   id: 'Partners Earnings',
        //   title: <FormattedMessage id="Partners Earnings" />,
        //   type: 'item',
        //   url: '/earnings/partners-earnings'
        // }
      ]
    }
  ]
};

export default formsTables;
